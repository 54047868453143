<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div class="spinner-border mt-3" style="width: 5rem; height: 5rem;color:#008D36" role="status">
          <span class="sr-only">Cargando...</span>
        </div> 
        <h2 class="mb-1 mt-3">
          En breve conoceras el mejor proyecto para invertir
        </h2>       
        <b-img class="mt-3"
            fluid
            :src="imgUrl"
            alt="Login V2"
        />
      </div>
    </div>
  </div>

</template>


<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg, BSpinner,BRow, BCol } from 'bootstrap-vue'
import store from '@/store/index'
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getAbilitiesBasedOnUserType } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'


export default {
  components: {        
      BLink,
      BButton,
      BImg,
      BSpinner,
      BRow,
      BCol
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/sosty-login.png'),
      redirectToProjectCode: null,
      email: null,
      user:{},
      projectCode: null
      }
  },
  created() {
    setTimeout(() => {          
      this.RedirectToFeaturedProject();        
    },1000) 
  },

  computed: {
      imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {            
          this.downImg = require('@/assets/images/pages/sosty-login.png')
          return this.downImg
      }
      return this.downImg
      },
  },

  methods:{
    RedirectToFeaturedProject(){
      axios.get('/GeneralConfigurations/GetConfig', { params: { key: 'REDIRECTION_PROJECT' }})
      .then(response => {         
          this.$router.replace({path: 'public-project-details?projectCode=' + response.data.value})
      })
    }, 
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
